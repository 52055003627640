<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('farm_report.supplier_wise_machine_assign_report') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('farm_config.company_name')"
                  label-for="supplier_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.supplier_id"
                      :options="supplierList"
                      id="supplier_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="3"
                  :label="$t('farm_config.machine_type')"
                  label-for="machine_type_id"
                  >
                  <b-form-select
                    plain
                    v-model="search.machine_type_id"
                    :options="machineTypeList"
                    id="machine_type_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('farm_config.machine_name')"
                  label-for="machine_id"
                  >
                  <b-form-select
                    plain
                    v-model="search.machine_id"
                    :options="machineList"
                    id="machine_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('farm_report.supplier_wise_machine_assign_report') }} {{ $t('globalTrans.list')}}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('farm_report.supplier_wise_machine_assign_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                <div class="text-black">
                                <b-row>
                                  <b-col md="3"><strong>{{ $t('farm_config.company_name') }}: </strong>{{ search.supplier_id ? getSupplierList(search.supplier_id) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_config.machine_type') }}: </strong>{{ search.machine_type_id ? getMachineTypeName(search.machine_type_id) : $t('globalTrans.all') }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_config.machine_name') }}: </strong>{{ search.machine_id ? getMachineName(search.machine_id): $t('globalTrans.all')  }}</b-col>
                                </b-row>
                              </div>
                                <template>
                                  <b-table-simple class="tg mt-3" bordered hover row-hovered small caption-top responsive show-empty>
                                    <b-thead>
                                      <b-tr>
                                        <b-th class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.company_name') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.machine_type') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.machine_name') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.brand') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.country_origin_name') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.country_prepared_name') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.horse_power') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.model_no') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.extended_model_no') }}</b-th>
                                        <b-th class="text-center" colspan="2">{{ $t('farm_report.amount_development_assistance') }}</b-th>
                                        <b-th class="text-center" rowspan="2">{{ $t('farm_config.comment') }}</b-th>
                                      </b-tr>
                                      <b-tr>
                                        <b-th class="text-center">{{ $t('farm_config.plain_area') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.coastal_area') }}</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                      <template v-if="datas.length">
                                        <template v-for="(item, index) in datas">
                                          <template v-for="(info, infoIndex) in item.company_info">
                                            <b-tr :key="'dt-'+ index + '-gt-' + infoIndex">
                                              <slot v-if="infoIndex === 0">
                                                <b-td class="text-center" :rowspan="item.company_info.length">{{ $n(item.count) }}</b-td>
                                                <b-td class="text-center" :rowspan="item.company_info.length">
                                                  {{ getSupplierList(item.supplier) }}
                                                </b-td>
                                              </slot>
                                              <b-td class="text-center">{{ getMachineTypeName(info.machine_type_id) }}</b-td>
                                              <b-td class="text-center">{{ getMachineName(info.machine_id) }}</b-td>
                                              <b-td class="text-center">{{ getBrandList(info.brand_id) }}</b-td>
                                              <b-td class="text-center">{{ getCountryOriginName(info.country_origin_id) }}</b-td>
                                              <b-td class="text-center">{{ getCountryPreparedName(info.country_prepared_id) }}</b-td>
                                              <b-td class="text-center">{{ $n(info.horse_power, { useGrouping: false }) }}</b-td>
                                              <b-td class="text-center">{{ info.model }}</b-td>
                                              <b-td class="text-center">{{ info.extended_model }}</b-td>
                                              <b-td class="text-center">{{ $n(info.plain_area, { useGrouping: false }) }}</b-td>
                                              <b-td class="text-center">{{ $n(info.coastal_area, { useGrouping: false }) }}</b-td>
                                              <b-td class="text-center">{{ info.comment }}</b-td>
                                            </b-tr>
                                          </template>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <b-tr>
                                          <b-td colspan="13" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                        </b-tr>
                                      </template>
                                    </b-tbody>
                                  </b-table-simple>
                                </template>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { core } from '@/config/pluginInit'
  import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
  import { supplierWiseMachineAssignReport } from '../../api/routes'
  import flatpickr from 'flatpickr'
  import ListReportHead from '@/components/custom/ListReportHead.vue'
  import ExportPdf from './export-pdf_details'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  Vue.use(excel)

  export default {
      props: ['id'],
      components: {
          ListReportHead
      },
      data () {
        return {
          search: {
            supplier_id: 0,
            machine_type_id: 0,
            machine_id: 0
          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          loading: false,
          showData: false,
          instrumentList: [],
          supplierList: [],
          machineList: []
        }
      },
      created () {
        this.getSupplierName()
      },
      mounted () {
          core.index()
          flatpickr('.datepicker', {})
          this.headerDataExcel()
      },
      computed: {
        machineTypeList: function () {
          return this.$store.state.incentiveGrant.commonObj.macMachineTypeList.filter(item => item.status === 1)
        }
      },
      watch: {
        'search.machine_type_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.machineList = this.getInstrumentList(newVal)
          }
        }
      },
      methods: {
        getInstrumentList (machineTypeId = null) {
          const machineTypeList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
          if (machineTypeId) {
            return machineTypeList.filter(item => item.machine_type_id === machineTypeId)
          }
          return machineTypeList
        },
        headerDataExcel () {
          RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/2').then(response => {
            if (response.success) {
              const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 2)
              const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
              const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
              this.headerExcelDefault.orgName = orgName
              this.headerExcelDefault.orgNameBn = orgNameBn
              this.headerExcelDefault.address = response.data.address
              this.headerExcelDefault.address_bn = response.data.address_bn
            }
          })
        },
        pdfExport () {
            const reportTitle = this.$t('farm_report.supplier_wise_machine_assign_report')
            ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.search, this.getMachineTypeName, this.getSupplierList, this.getMachineName, this.getBrandList, this.getCountryOriginName, this.getCountryPreparedName)
        },
        async searchData () {
          this.loading = true
          this.showData = true
          const params = Object.assign({}, this.search)
          await RestApi.getData(incentiveGrantServiceBaseUrl, supplierWiseMachineAssignReport, params).then(response => {
            if (response.success) {
              this.datas = response.data
            } else {
              this.datas = []
            }
          })
          this.loading = false
        },
        async getSupplierName () {
          this.loading = true
          const result = await RestApi.getData(incentiveGrantServiceBaseUrl, '/farmMachinery/config/supplier-wise-machine-assign/supplier-list')
          if (result.success) {
            this.supplierList = result.data.map(item => {
              const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
              return Object.assign({}, item, tmp)
            })
          } else {
            this.supplierList = []
          }
          this.loading = false
        },
        getSupplierList (supplierId) {
          const obj = this.supplierList.find(el => el.value === supplierId)
          if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          }
        },
        getMachineTypeName (machineTypeId) {
          const obj = this.$store.state.incentiveGrant.commonObj.macMachineTypeList.find(el => el.value === machineTypeId)
          if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          }
        },
        getMachineName (machineId) {
          const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(el => el.value === machineId)
          if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          }
        },
        getBrandList (brandId) {
          const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(el => el.value === parseInt(brandId))
          if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          }
        },
        getCountryOriginName (countryOriginId) {
          const obj = this.$store.state.commonObj.countryList.find(el => el.value === parseInt(countryOriginId))
          if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          }
        },
        getCountryPreparedName (countryPreparedId) {
          const obj = this.$store.state.commonObj.countryList.find(el => el.value === parseInt(countryPreparedId))
          if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          }
        }
      }
  }
  </script>
