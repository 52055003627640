import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search, getMachineTypeName, getSupplierList, getMachineName, getBrandList, getCountryOriginName, getCountryPreparedName) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = [
          [
            { text: vm.$t('farm_config.company_name') + ' : ' + (search.supplier_id ? getSupplierList(search.supplier_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('farm_config.machine_type') + ' : ' + (search.machine_type_id ? getMachineTypeName(search.machine_type_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('farm_config.machine_name') + ' : ' + (search.machine_id ? getMachineName(search.machine_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.company_name'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.machine_type'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.machine_name'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.brand'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.country_origin_name'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.country_prepared_name'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.horse_power'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.model_no'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_config.extended_model_no'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('farm_report.amount_development_assistance'), colSpan: 2, style: 'th', alignment: 'center', bold: true },
              {},
              { text: vm.$t('farm_config.comment'), rowSpan: 2, style: 'th', alignment: 'center', bold: true }
          ],
          [
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { text: vm.$t('farm_config.plain_area'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('farm_config.coastal_area'), style: 'th', alignment: 'center', bold: true },
            { }
        ]
        ]
          if (data.length > 0) {
            data.forEach((item, optionIndex) => {
              item.company_info.forEach((info1, index2) => {
                const NewLoop = []
                if (index2 === 0) {
                  NewLoop.push({ text: vm.$n(optionIndex + 1), style: 'td', rowSpan: item.company_info.length, alignment: 'center' })
                  NewLoop.push({ text: getSupplierList(item.supplier), style: 'td', rowSpan: item.company_info.length, alignment: 'center' })
                } else {
                  NewLoop.push({})
                  NewLoop.push({})
                }
                NewLoop.push({ text: getMachineTypeName(info1.machine_type_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: getMachineName(info1.machine_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: getBrandList(info1.brand_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: getCountryOriginName(info1.country_origin_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: getCountryPreparedName(info1.country_prepared_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.$n(info1.horse_power, { useGrouping: false }), alignment: 'center', style: 'td' })
                NewLoop.push({ text: info1.model, alignment: 'center', style: 'td' })
                NewLoop.push({ text: info1.extended_model, alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.$n(info1.plain_area, { useGrouping: false }), alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.$n(info1.coastal_area, { useGrouping: false }), alignment: 'center', style: 'td' })
                NewLoop.push({ text: info1.comment, alignment: 'center', style: 'td' })
                allRows.push(NewLoop)
              })
            })
            allRows.push(
            )
           } else {
              allRows.push(
                [
                  { text: vm.$t('globalTrans.noDataFound'), colSpan: '13', style: 'td', alignment: 'center' },
                  {},
                  {},
                  {},
                  {},
                  {}
                ]
            )
          }
          pdfContent.push({
            table: {
              headerRows: 2,
              widths: ['5%', '10%', '10%', '10%', '7%', '7%', '7%', '7%', '10%', '7%', '7%', '7%', '7%'],
              body: allRows
            }
          })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
